import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, UPDATE_USER } from '../ActionTypes';

const initState = {
  login: null,
  token: null,
  user: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        token: action.token,
        user: action.user,
        loading: false,
        error: null
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
        token: null,
        user: null,
        error: null
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_USER:
      if(state.user._id === data._id) {
        return {
          ...state,
          loading: false,
          user: data,
          token: action.token,
        }
      }

    default:
      return state;
  }
};
export default AuthReducer;
